import React from "react"
import PropTypes from "prop-types"

import IndexPageTemplate from "src/components/IndexPage"

const IndexPage = ({ pageContext }) => (
  <IndexPageTemplate pageContext={pageContext} />
)

IndexPage.propTypes = {
  pageContext: PropTypes.object,
}

export default IndexPage
