import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { groupBy } from "lodash"
import { cursToPath } from "src/utils"
import Layout from "src/components/Layout"
import SEO from "src/components/SEO"
import LangPicker from "src/components/LangPicker"
import cursinhos from "src/queries/cursinhos"
import { useTranslation } from "react-i18next"
import { changeLangIfNeeded } from "src/i18n"
import s from "./IndexPage.module.scss"

// Group and sort by uf and city.
function groupCursinhos(cursinhos) {
  cursinhos = cursinhos.filter((c) => c.show)
  let byUf = Object.entries(groupBy(cursinhos, (c) => c.uf))
  byUf.sort()
  for (let item of byUf) {
    let byCity = Object.entries(groupBy(item[1], (c) => c.city))
    byCity.sort()
    item[1] = byCity
  }
  return byUf
}

const CursItem = ({ c, lang }) => (
  <Link to={"/" + cursToPath(c, lang)}>
    <div className={s.link}>
      <strong>{c.name}</strong>
      <br />
      {c.place} <em>({c.address})</em>
    </div>
  </Link>
)

export const IndexPageTemplate = ({ pageContext: { lang } }) => {
  const { t, i18n } = useTranslation()
  let data = cursinhos()
  let html = data.html
  let curs = data.frontmatter.cursinhos

  changeLangIfNeeded(i18n, lang)
  if (i18n.language !== "pt") {
    curs = curs.filter((c) => c.foreigners)
  }

  return (
    <Layout>
      <SEO title={t("title")} />
      {/* <div className={s.langBox}>
        <p>{t("homeLangIntro")}</p>
        <LangPicker />
      </div> */}
      <h1>{t("title")}</h1>
      {i18n.language === "pt" ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : null}
      {groupCursinhos(curs).map(([uf, ufCurs]) => (
        <div key={uf}>
          <h2>{uf}</h2>
          {ufCurs.map(([city, cityCurs]) => (
            <div key={city}>
              <h3>{city}</h3>
              <ul className="ul-no-style">
                {cityCurs.map((c) => (
                  <li key={c.slug}>
                    <CursItem c={c} lang={i18n.language} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </Layout>
  )
}

IndexPageTemplate.propTypes = {
  pageContext: PropTypes.object,
}

export default IndexPageTemplate
