import { graphql, useStaticQuery } from "gatsby"

export default () =>
  useStaticQuery(
    graphql`
      query CURSINHOS {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          html
          frontmatter {
            cursinhos {
              address
              city
              coordination {
                name
                phone
              }
              emails
              facebook
              name
              place
              show
              slug
              time
              uf
              foreigners
            }
          }
        }
      }
    `
  ).markdownRemark
